<template>
  <div class="mt-3 wrap-con">
    <span
      class="click_btn"
      @click.stop="onAddBtnClick()"
    >
      <img
        src="@/assets/images/main/svg/svg_add.svg"
        class="ml-1"
      >
      {{ '설치내역 추가'|translate }}
    </span>
    <div
      v-for="(item, idx) in editContext"
      :key="idx"
      class="item-card-wrap"
    >
      <div class="font-weight-bold">
        {{ item.installDate | date }}
      </div>
      <div>
        {{ item.printerModelName }} ({{ item.printerSerialNo }})
      </div>
      <div class="font-weight-bold">
        {{ '초기 페이지'|translate }}
      </div>
      <div class="page-count-wrap">
        <div>{{ '흑백'|translate }}A4: {{ item.totalMonoPagecount | currency }}</div>
        <div>{{ '흑백'|translate }}A3: {{ item.totalMonoPagecountA3 | currency }}</div>
      </div>
      <div class="page-count-wrap">
        <div>{{ '컬러'|translate }}A4: {{ item.totalColorPagecount | currency }}</div>
        <div>{{ '컬러'|translate }}A3: {{ item.totalColorPagecountA3 | currency }}</div>
      </div>
      <div class="font-weight-bold">
        {{ '메모'|translate }}
      </div>
      <div class="memo">
        {{ item.memo }}
      </div>
      <div
        class="inner-btn-wrap"
        @click.stop="item.isMore = !item.isMore"
      >
        <img
          src="@/assets/images/main/svg/button_More.svg"
        >
        <div
          v-show="item.isMore"
          @click.stop="onAddBtnClick(idx)"
        >
          {{ '수정'|translate }}
        </div>
        <div
          v-show="item.isMore"
          @click.stop="onDeleteBtnClick(idx)"
        >
          {{ '삭제'|translate }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import {PATH} from "@/constants";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import {ETC} from "@/constants/etc-code";
    import Permissions from "@/constants/permissions";

    export default {
        name: 'PrinterContractInstallLogComponent',
        components: {},
        extends: WindowComponent,
        mixins: [ModalVuetifyUser],
        props: {
            contractContext: {type: Object, default: null},
            accountId: undefined
        },
        data: function () {
            return {
                context: {},
                editContext: {},
                uniqueModalId: null,
            }
        },
        computed: {
          editPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
          }
        },
        watch: {
            contractContext: {
                deep: true,
                handler() {
                    this.load();
                },
            }
        },
        created() {
        },
        mounted() {
            this.load();
        },
        methods: {
            init() {
            },
            load() {
                this.context = null;
                this.editContext = null;

                this.$request(PATH.PRINTER_INSTALL_LOG_LIST)
                    .setObject({printerContractId: this.contractContext.printerContractId})
                    .enqueue()
                    .then(res => {
                        this.context = res.data.map(e => {
                            e['isMore'] = false;
                            return e
                        })
                        this.editContext = this.$cloneDeep(this.context)
                    })
                    .catch(error => {
                        console.error(JSON.stringify(error));
                    });
            },
            updatePrinter(param) {

                let item = param.model
                let idx = param.idx
                if (!item) {
                    this.editContext[idx].printerModelName = null;
                    this.editContext[idx].printerModelId = null;
                    this.editContext[idx].monitoringPrinterId = null;
                }
                if (item.isPrinter) {
                    this.editContext[idx].printerModelName = item.raw.printerModelName;
                    this.editContext[idx].printerModelId = item.raw.printerModelId;
                    this.editContext[idx].monitoringPrinterId = item.raw.monitoringPrinterId;
                    this.editContext[idx].printerSerialNo = item.raw.printerSerialNo;
                } else if (item.isModel) {
                    this.editContext[idx].printerModelName = item.raw.printerModelName;
                    this.editContext[idx].printerModelId = item.raw.printerModelId;
                    this.editContext[idx].monitoringPrinterId = null;
                    this.editContext[idx].printerSerialNo = null;
                } else if (item.isCodeModel) {
                    this.editContext[idx].printerModelName = item.raw.codeValue;
                    this.editContext[idx].printerModelId = item.raw.codeKey;
                    this.editContext[idx].monitoringPrinterId = null;
                    this.editContext[idx].printerSerialNo = null;
                }
            },
            onAddBtnClick(idx) {
              if(!this.editPermission) {
                return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
              }
                this.uniqueModalId = this.openModalVuetify(MODAL_VUETIFY_NAMES.PRINTER_INSTALL_LOG.ADD_MODIFY, {
                        persistent: true,
                        maxWidth: "500px",
                    }, !idx ? {
                        accountId: this.contractContext.accountId,
                        deleteYn: 'N',
                        installDate: new Date().toISOString(),
                        memo: "",
                        monitoringPrinterId: null,
                        printerContractId: this.contractContext.printerContractId,
                        printerInstallLogId: null,
                        printerModelId: null,
                        printerModelName: null,
                        printerSerialNo: '',
                        totalColorPagecount: 0,
                        totalColorPagecountA3: 0,
                        totalMonoPagecount: 0,
                        totalMonoPagecountA3: 0,
                        totalPagecount: 0,
                    } : this.editContext[idx],
                    (data) => {
                        this.onSaveBtnClick(data)
                    }
                );
            },
            onSaveBtnClick(data) {
                if (confirm(this.$translate('저장하시겠습니까?'))) {
                    this.requestServer(!data.printerInstallLogId ? PATH.PRINTER_INSTALL_LOG_REG : PATH.PRINTER_INSTALL_LOG_UPD, data, e => {
                            this.$alert(this.$translate('저장이 완료 되었습니다.'))
                            this.load()
                        }, e => {
                            this.$alert(this.$translate('저장이 실패하였습니다.'))
                        }
                    )
                }
            },
            onDeleteBtnClick(idx) {
                if (confirm(this.$translate('삭제하시겠습니까?'))) {
                    if (idx >= this.context.length) {
                        this.editContext.splice(idx, 1)
                        this.$alert(this.$translate('삭제가 완료되었습니다.'))
                        return;
                    } else {
                        this.editContext[idx].deleteYn = 'Y'
                        this.requestServer(PATH.PRINTER_INSTALL_LOG_UPD, this.editContext[idx], e => {
                                this.$alert(this.$translate('삭제되었습니다.'))
                                this.load()
                            }, e => {
                                this.$alert(this.$translate('삭제가 실패하였습니다.'))
                            }
                        )
                    }
                }
            },
            requestServer(url, param, successFunc, failFunc) {
                this.$request(url)
                    .setObject(param)
                    .enqueue()
                    .then(res => {
                        successFunc()
                    })
                    .catch(error => {
                        console.error(JSON.stringify(error));
                        failFunc()
                    });
            },
        },
    };
</script>

<style scoped>
    .wrap-con > .item-card-wrap {
        margin: 5px 0;
        padding: 8px;
    }

    .item-card-wrap {
        position: relative;
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        text-align: left;
        overflow: hidden;
    }

    .item-card-wrap > div {
        height: 24px;
        line-height: 20px;
        padding: 0 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.875rem;
    }

    .item-card-wrap .font-weight-bold {
        font-size: 0.9375em;
        margin-top: 8px;
        color: #333;
    }

    .item-card-wrap .page-count-wrap {
        display: flex;
        justify-content: flex-start;
    }

    .item-card-wrap .page-count-wrap div {
        width: 50%;
    }

    .item-card-wrap .memo {
        height: 50px;
        margin: 0 5px;
        overflow-y: scroll;
        white-space: normal;
        border: 1px solid #DDDDDD;
        background: #ffffff;
    }

    .click_btn {
        cursor: pointer;
        display: inline-flex;
        line-height: 24px;
        color: #0C98FE;
        font-size: 0.875rem;
    }

    .inner-btn-wrap {
        overflow: visible !important;
        position: absolute;
        top: 12px;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 50px;
        align-items: flex-end;
    }

    .inner-btn-wrap > img {
        width: 30px;
        height: 22px;
    }

    .inner-btn-wrap div {
        overflow: visible;
        display: flex;
        align-items: center;
        padding: 4px 18px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #dddddd;
        margin-top: 5px;
        margin-bottom: -6px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .inner-btn-wrap div img {
        width: 15px;
        margin: 0 5px;
    }
</style>
